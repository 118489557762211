/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable no-irregular-whitespace */
/* eslint-disable no-empty-pattern */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { useState } from "react";
import { graphql, PageProps } from "gatsby";
import { Query } from "../../generated/graphql-types";
import { Article, Hero, I18Link, Quote } from "../../components";
// eslint-disable-next-line import/named
import { buildMenu, Layout } from "../../layouts";
import socialHead2 from "../../images/header-wiredup-diagnostics-analysis.jpg";
import Value_analysis_icon from "../../images/Value-analysis-icon.png";
import Business_modelling_icon from "../../images/Business modelling.png";
import Key_performance_indicators__icon from "../../images/Key-performance-indicators-icon.png";
import uparrow from "../../images/top-button.svg";
// import Key_performance_indicators from "../../images/Key-performance-indicators.png";
import Key_performance_indicators from "../../images/wiredup-diagnostic-key-performance.png";
import Business_modelling from "../../images/wiredup-diagnostic-business-modeling.png";
import Value_analysis from "../../images/wiredup-diagnostic-value-analysis.png";
import person_img from "../../images/Quotation_mark.png";
import leftIcon from "../../images/Left Button.svg";
import Attribution from "../../images/VH-Attribution.png";
import Sensitivity from "../../images/VH-Sensitivity.png";
import Variance from "../../images/VH-Variance.png";
const Image = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { socialHead2 },
      srcSet: socialHead2,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1440,
  height: 720,
};
const person = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 600px) 600px, 100vw",
      src: { person_img },
      srcSet: person_img,
      sources: [],
      layout: "constrained",
      width: 600,
    },
  },
  width: 600,
  height: 600,
};
const Business_modelling_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Business_modelling },
      srcSet: Business_modelling,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const Key_performance_indicators_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Key_performance_indicators },
      srcSet: Key_performance_indicators,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const valueanaysis_img = {
  layout: "constrained",
  backgroundColor: "#282828",
  images: {
    fallback: {
      sizes: "(min-width: 1280px) 1280px, 100vw",
      src: { Value_analysis },
      srcSet: Value_analysis,
      sources: [],
      layout: "constrained",
      width: 1280,
    },
  },
  width: 1280,
  height: 720,
};
const persondetails = {
  company: null,
  country: "Australia and New Zealand",
  image: person,
  jobTitle: "Senior General Manager",
  link: "/people/skipp-williamson-et",
  linkedin: "https://www.linkedin.com/in/skippwilliamson/",
  name: "Iron Ore Mine",
  quote:
    "In 30 years of consulting, I have not seen a change that will be as transformative and as positive for most of our clients’ economics as energy transition. We first started working on energy efficiency 20 years ago, but the last five years have created exponentially accelerating changes in the pace of requirements and reductions in costs curves. Great opportunities are opening for those who frame the problems well and act fast.",
  sector: "Infrastructure",
};
function clikinguparrow() {
  window.scrollTo({ top: 0, behavior: "smooth" });
  return;
}
const ResearchDocument: React.FC<
  PageProps<Pick<Query, "allGraphCmsResearchDocument" | "graphCmsSectorPage">>
> = ({
  path,
  data: {
    allGraphCmsResearchDocument: {},
    graphCmsSectorPage: sectorPage,
  },
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const openModal = (imageSrc) => {
    setSelectedImage(imageSrc);
    setModalVisible(true);
  };

  const closeModal = () => {
    setSelectedImage(null);
    setModalVisible(false);
  };
  if (!ResearchDocument) return null;
  return (
    <Layout
      pagePath={path}
      menu={buildMenu(sectorPage?.menu)}
      footerMenu={buildMenu(sectorPage?.footerMenu)}
      keywords={
        "diagnostic analysis, Value driver diagnostic, Value Driver Analysis"
      }
      title={"Diagnostics and Analytics | Value Driver Trees by WiredUp"}
      description={
        "WiredUp’s business value driver trees help you visualise your business processes, track real-time performance and run diagnostic analysis."
      }
    >
      <Hero
        title="Diagnostics and analysis"
        subheading=""
        video=""
        image={Image}
        short={true}
        treatment={sectorPage?.heroTreatment}
        showSearch={true}
      />
      <div className="py-60px lg:grid-cols-3 grid-cols-1 p-10px page-grid row w-auto m-auto md:w-full pipware-diagnostic-statement-div">
        <a
          href="#Key_performance_indicators"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div className="">
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Key_performance_indicators__icon}
                  alt="business-performance-kpi"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Key performance indicators</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Create custom KPIs and track metrics in real time for up-to-date
                information on process performance
              </p>
            </div>
          </div>
        </a>
        <a
          href="#value_driver_tree"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Business_modelling_icon}
                  alt="business-value-driver-tree"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4">Value driver diagnostic</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Model key processes or the whole organisation with value driver
                trees that identify bottlenecks, inefficient operations and key
                value generators
              </p>
            </div>
          </div>
        </a>
        <a
          href="#Value_analysis"
          className="pipware-model-content pipware-model-content-div lg:mt-0 mt-15px"
        >
          <div>
            <div className="page-grid grid-cols-1">
              <div className="p-20px m-auto">
                <img
                  src={Value_analysis_icon}
                  alt="attribution analysis"
                  className="w-100px h-100px"
                ></img>
              </div>
              <div className="pl-30px text-left">
                <h4 className="h4 ">Value analysis</h4>
              </div>
            </div>
            <div className="p-30px">
              <p className="p">
                Run sensitivity, attribution or what-if analyses and uncover how
                changing KPIs drive value across your organisation
              </p>
            </div>
          </div>
        </a>
      </div>

      <Quote
        quote={`"WiredUp is a game changer. It provides us with better visibility on our KPIs and accountability for actions."`}
        profile={persondetails}
        withoutqote="YES"
        authorPosition=""
      />
      <div id="Key_performance_indicators">
        <Article
          side="right"
          id={""}
          image={Key_performance_indicators_img}
          alt={"business-performance-kpi"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Empower your organisation to achieve goals by effectively tracking key metrics with custom KPIs. Whether you want to increase production or reduce downtime, WiredUp allows you to effortlessly set baseline or performance targets and revise them as needed with actuals data, giving you a clear eye on the performance of every process so you can leverage real-time measurements to fuel your organisation.",
              heading: [],
            },
          ]}
          listing={[]}
          smallfont="YES"
          Articletitle="Track and manage key performance indicators"
          readmoreLink="#analysis"
        />
      </div>
      <div id="value_driver_tree">
        <Article
          side="left"
          id={""}
          image={Business_modelling_img}
          alt={"business-value-driver-tree"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Map out workflows in your organisation with value driver trees (VDTs) to easily visualise every key process and link them to your KPIs. Track real-time performance measurements for specific processes or for your entire operations, enabling faster and more informed decision-making.",
              heading: [],
            },
            {
              id: "",
              title:
                "WiredUp’s user-friendly VDTs provide a clear and comprehensive view of your operations, accommodating thousands of nodes driven by different KPIs. They help you identify bottlenecks, inefficiencies and key value drivers, giving you a deeper insight into where to drive improvements in your organisation.",
              heading: [],
            },
          ]}
          listing={[]}
          Articletitle="Track change with value driver trees"
          smallfont="YES"
          readmoreLink="#analysis"
        />
      </div>
      <div id="Value_analysis">
        <Article
          side="right"
          id={""}
          image={valueanaysis_img}
          alt={"attribution analysis"}
          articlewithContent="YES"
          statements={[
            {
              id: "",
              title:
                "Uncover new insights through advanced analytics (sensitivity, attribution and variance) applied to each VDT and see the impact changing KPIs have on value drivers across your organisation.",
              heading: [],
            },
            {
              id: "",
              title:
                "Every analysis uses up-to-date KPI values so you can identify the best levers to achieve your desired outcomes, whether it is to improve specific operations or to drive a global transformation. Explore them all below.",
              heading: [],
            },
          ]}
          listing={[]}
          smallfont="YES"
          Articletitle="Improve processes through value analysis"
          readmoreLink="#analysis"
        />
      </div>
      <div className="lg:p-35px p-0" id="analysis">
        <center>
          <h3 className="h3">Uncover new ways to unleash potential</h3>
          <br></br>
          <h5 className="">
            Explore different ways to analyse value drivers across your
            organisation
          </h5>
        </center>
        <div className="page-grid lg:grid-cols-3 md:grid-cols-1 grid-cols-1 p-30px">
          <div className="lg:p-20px p-0 m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a onClick={() => openModal(Sensitivity)}>
                <img src={Sensitivity} alt="img" className=""></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-diagnostic-and-analysis/">
                <h4 className="h4">Sensitivity analysis</h4>
              </a>
            </div>
            <div className="mt-15px mb-15px wiredup-h-100">
              <p className="lg:w-desktop-4-cols w-auto m-auto p-20px">
                Sensitivity analysis helps you improve decision-making across
                your organisation by calculating which input KPI’s have the
                biggest impact on each business process model so you can see how
                each lever is affected when variance is introduced.
              </p>
            </div>
          </div>
          <div className="lg:p-20px p-0 m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a onClick={() => openModal(Attribution)}>
                <img src={Attribution} alt="img" className=""></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-esg-management-software/">
                <h4 className="h4">Attribution analysis</h4>
              </a>
            </div>
            <div className="mt-15px mb-15px wiredup-h-100">
              <p className="lg:w-desktop-4-cols w-auto m-auto p-20px">
                Identify performance and underperformance by comparing values
                across KPIs to determine how fluctuations impact value drivers.
                WiredUp’s attribution analysis allows you to compare control
                fields against analysis fields, giving you a better
                understanding of how business process model outcomes are
                affected by KPI variance.
              </p>
            </div>
          </div>
          <div className="lg:p-20px p-0 m-auto page-grid grid-cols-1">
            <div className="p-20px m-auto">
              <a onClick={() => openModal(Variance)}>
                <img src={Variance} alt="img" className=""></img>
              </a>
            </div>
            <div className="m-auto">
              <a href="/en/wiredup-esg-management-software/">
                <h4 className="h4">Variance analysis</h4>
              </a>
            </div>
            <div className="mt-15px mb-15px wiredup-h-100">
              <p className="lg:w-desktop-4-cols w-auto m-auto p-20px">
                Uncover performance across your business by selecting individual
                percentage or absolute variance values per KPI and calculating
                how they impact the business process model outcome. This allows
                you to see the difference between planned and actual values,
                highlighting any areas which need attention or improvement.
              </p>
            </div>
          </div>
        </div>
      </div>
      <p className="col-span-12 col-start-2 text-center p-20px bg-light-blue mt-20px">
        Find out how WiredUp can help your organisation make a lasting impact{" "}
        <b>
          <br></br>
          <center>
            <a href={"/en/wiredup-enquiry/"} className="text-dark-blue">
              Schedule a demo
            </a>
          </center>
        </b>
      </p>
      <section className="py-section page-grid max-width z-10 gap-y-20px">
        <I18Link
          to={"/wiredup-change-management-software"}
          className="flex items-center col-start-2 col-span-12 md:col-start-2 md:col-span-6"
        >
          <img
            src={leftIcon}
            alt="Left"
            className="pr-mobile-gap md:pr-tablet-gap lg:pr-desktop-gap"
          />
          Back to WiredUp
        </I18Link>
      </section>
      <br></br>
      <button id="scrollToTopBtn" onClick={clikinguparrow}>
        <img src={uparrow} className="uparrow_wiredup"></img>
        {/* <div className="float-button-arrow bg-no-repeat bg-28px min-h-28px w-30px h-30px"></div> */}
      </button>
      {modalVisible && (
        <div className="modal" onClick={closeModal}>
          <img
            src={selectedImage}
            alt="Selected Image"
            className="modal-content"
          />
        </div>
      )}
    </Layout>
  );
};

export default ResearchDocument;

export const IndexQuery = graphql`
  {
    allGraphCmsResearchDocument {
      nodes {
        id
        documentName
        documentThumbnail {
          url
        }
        documentDescription
        documents {
          url
        }
      }
    }
    graphCmsSectorPage {
      sectorType
      pageTitle
      keywords
      description
      menu {
        ...MenuFragment
      }
      businessChallengesIntroduction
      businessChallenges {
        headline
        id
        content {
          cleaned
        }
      }
      heroImage {
        ...ImageFragment
      }
      statement {
        cleaned
      }
      heroVideo {
        ...VideoFragment
      }
      heroTreatment
      id
      keyStatistic
      leadership {
        ...ProfileFragment
      }
      leadershipTitle
      quote
      quoteFrom {
        ...ProfileFragment
      }
      infographicIntroduction {
        cleaned
      }
      infographicDetails {
        cleaned
      }
      genericInfographicText {
        cleaned
      }
      sector
      slug
      statisticDetails
      storiesTitle
      storiesImage {
        ...ImageFragment
      }
      successes {
        __typename
        ... on GraphCMS_CaseStudy {
          ...CaseStudyReferenceFragment
        }
        ... on GraphCMS_Story {
          ...StoryReferenceFragment
        }
        ... on GraphCMS_Insight {
          ...InsightReferenceFragment
        }
      }
      form {
        ...FormFragment
      }
    }
  }
`;
